import { communityApi } from 'store/services/communityApi'
import {
  snakeToCamelObjectKeys,
  htmlEncode
} from '@purple/common/utils/commonUtility'
import { current } from '@reduxjs/toolkit'

export const boardQuery = communityApi.injectEndpoints({
  endpoints: (build) => ({
    // 공유하기 URL 정보 알기위한 게시판 조회
    getBoard: build.query({
      query: ({ serviceAlias = 'purple', boardAlias }) => ({
        url: `/ncommunity/${serviceAlias}/board/${boardAlias}`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0
    }),
    // 나의 일일 신고 횟수 조회
    getTodayReportedCount: build.query({
      query: ({ serviceAlias = 'purple' }) => ({
        url: `/ncommunity/${serviceAlias}/user/my/today_reported_count`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0
    }),
    /**
     * 게시판 목록 조회(더보기)
     * @param {object} params {
     *  "category_id": Integer
     *  "previous_article_id": Long
     *  "more_size": Integer
     *  "query": String
     *  "search_type": String
     *  "language": String
     * }
     */
    getArticleList: build.query({
      query: ({
        serviceAlias = 'purple',
        boardAlias,

        params
      }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/morearticle`,
        method: 'GET',
        params: params
      }),
      providesTags: ['articleList'],
      serializeQueryArgs: ({ queryArgs: { params, boardAlias } }) => ({
        query: params?.query,
        category: params?.category,
        boardAlias,
        moreSize: params?.more_size
      }),
      transformResponse: (response, meta, arg) => ({
        ...response,
        categoryAlias: arg.categoryAlias
      }),
      merge: (currentCache, newItems, { arg }) => {
        const isSameCategory = currentCache.categoryAlias === arg.categoryAlias
        const hasPreviousArticleId = !!arg.params.previous_article_id

        const updateCurrentCache = (newItems) => {
          currentCache.content_list = newItems.content_list
        }

        const appendToCurrentCache = (newItems) => {
          currentCache.content_list.push(...newItems.content_list)
        }

        if (hasPreviousArticleId) {
          if (isSameCategory) {
            appendToCurrentCache(newItems)
          } else {
            updateCurrentCache(newItems)
          }
          currentCache.has_more = newItems.has_more
          currentCache.total_count = newItems.total_count
        } else {
          updateCurrentCache(newItems)
          currentCache.has_more = newItems.has_more
          currentCache.total_count = newItems.total_count
        }

        currentCache.categoryAlias = arg.categoryAlias
      }
    }),
    //상단공지
    getTopNoticeList: build.query({
      query: ({ serviceAlias = 'purple', boardAlias, params }) => ({
        url: `/ncommunity/${serviceAlias}/board/${boardAlias}/notice`,
        method: 'GET',
        params: params
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, params }
      }) => ({ serviceAlias, boardAlias, params }),
      keepUnusedDataFor: 0
    }),
    // 게시글 상세 조회
    getArticleDetail: build.query({
      query: ({ serviceAlias = 'purple', boardAlias, articleId }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}`,
        method: 'GET'
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, articleId }
      }) => ({ serviceAlias, boardAlias, articleId }),
      providesTags: ['articleDetail'],
      keepUnusedDataFor: 0
    }),

    getRecentArticle: build.query({
      query: ({ serviceAlias = 'purple', params }) => ({
        url: `/ncommunity/${serviceAlias}/all/recentarticle`,
        method: 'GET',
        params
      }),
      keepUnusedDataFor: 0
    }),
    // 게시글 신고
    setArticleReport: build.mutation({
      query: ({ serviceAlias = 'purple', boardAlias, articleId, params }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}/report`,
        method: 'POST',
        body: params
      }),
      keepUnusedDataFor: 0
    }),
    // 게시글 삭제
    deleteArticle: build.mutation({
      query: ({ serviceAlias = 'purple', boardAlias, articleId }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}`,
        method: 'DELETE'
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, articleId }
      }) => ({ serviceAlias, boardAlias, articleId }),
      invalidatesTags: ['articleList']
    }),
    // 카테고리 조회
    getCategories: build.query({
      query: ({ serviceAlias = 'purple', boardAlias }) => ({
        url: `/ncommunity/${serviceAlias}/board/${boardAlias}/depth_categories`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0
    }),
    /**
     * 서브 카테고리 조회
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} categoryAlias
     */
    getSubCategories: build.query({
      query: ({ serviceAlias = 'purple', boardAlias, categoryAlias }) => ({
        url: `/ncommunity/${serviceAlias}/board/${boardAlias}/categories/${categoryAlias}/subcategories`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0
    }),
    /**
     * 게시글 상세 추가정보
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     */
    getArticleExtra: build.query({
      query: ({ boardAlias, serviceAlias = 'purple', articleId }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}/extra`,
        method: 'GET'
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, articleId }
      }) => ({ serviceAlias, boardAlias, articleId }),
      keepUnusedDataFor: 0
    }),
    /**
     * 게시글 이전/다음글 목록 조회
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     */
    getPrevnextArticle: build.query({
      query: ({ boardAlias, serviceAlias = 'purple', articleId, params }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}/prevnext`,
        method: 'GET',
        params
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, articleId, params }
      }) => ({ serviceAlias, boardAlias, articleId, params }),
      keepUnusedDataFor: 0
    }),
    /**
     * 댓글 작성
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     * @param {object} params {
     *  "comment": Object
     * }
     */
    postComment: build.mutation({
      query: ({ boardAlias, serviceAlias = 'purple', articleId, params }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}/comment`,
        method: 'POST',
        body: params
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, articleId, params }
      }) => ({ serviceAlias, boardAlias, articleId, params })
      // invalidatesTags: [{ type: 'Comments', id: 'LIST' }]
    }),
    /**
     * 댓글 수정
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     * @param {string} commentId
     * @param {object} params {
     *  "comment": Object
     * }
     * @param {object} listArg {
     *  "max_size": String,
     *  "sort": String
     *  "more_direction": String
     *  "previous_comment_id": String
     * }
     */
    putComment: build.mutation({
      query: ({
        boardAlias,
        serviceAlias = 'purple',
        articleId,
        commentId,
        params
      }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/comment/${commentId}`,
        method: 'PUT',
        body: params
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, commentId, params }
      }) => ({ serviceAlias, boardAlias, commentId, params }),
      async onQueryStarted(
        {
          boardAlias,
          serviceAlias = 'purple',
          articleId,
          commentId,
          params,
          listArg
        },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          boardQuery.util.updateQueryData(
            'getCommentList',
            {
              boardAlias,
              serviceAlias,
              articleId,
              params: listArg
            },
            (draft) => {
              draft.content_list?.forEach((item, index) => {
                if (item.content.id === commentId) {
                  draft.content_list[index].content.content =
                    params.comment.contents
                }
              })
            }
          )
        )

        try {
          await queryFulfilled
        } catch (error) {
          patchResult.undo()
          console.error(error)
        }
      }
    }),
    /**
     * 댓글 삭제
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     * @param {string} commentId
     * @param {object} listArg {
     *  "max_size": String,
     *  "sort": String
     *  "more_direction": String
     *  "previous_comment_id": String
     * }
     */
    deleteComment: build.mutation({
      query: ({
        boardAlias,
        serviceAlias = 'purple',
        articleId,
        commentId,
        params
      }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/comment/${commentId}`,
        method: 'DELETE'
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, commentId }
      }) => ({ serviceAlias, boardAlias, commentId }),
      async onQueryStarted(
        {
          boardAlias,
          serviceAlias = 'purple',
          articleId,
          commentId,
          params,
          listArg
        },
        { dispatch, queryFulfilled }
      ) {
        const patchCommentResult = dispatch(
          boardQuery.util.updateQueryData(
            'getCommentList',
            {
              boardAlias,
              serviceAlias,
              articleId,
              params: listArg
            },
            (draft) => {
              draft.content_list = draft.content_list?.filter(
                (comment) => comment.content.id !== commentId
              )
            }
          )
        )

        const patchDetailResult = dispatch(
          boardQuery.util.updateQueryData(
            'getArticleDetail',
            {
              boardAlias,
              serviceAlias,
              articleId
            },
            (draft) => {
              draft.content_meta.reactions.comment_count -= 1
            }
          )
        )
        try {
          await queryFulfilled
        } catch (error) {
          patchCommentResult.undo()
          patchDetailResult.undo()
          console.error(error)
        }
      }
    }),
    /**
     * 댓글 정보 호출
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} commentId
     */
    getComment: build.query({
      query: ({ boardAlias, serviceAlias = 'purple', commentId }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/comment/${commentId}`,
        method: 'GET'
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, commentId }
      }) => ({ serviceAlias, boardAlias, commentId }),
      async onQueryStarted(
        { boardAlias, serviceAlias = 'purple', articleId, commentId, params },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled

          const patchListResult = dispatch(
            boardQuery.util.updateQueryData(
              'getCommentList',
              {
                boardAlias,
                serviceAlias,
                articleId,
                params
              },
              (draft) => {
                draft.content_list.unshift(data)
              }
            )
          )

          const patchDetailResult = dispatch(
            boardQuery.util.updateQueryData(
              'getArticleDetail',
              {
                boardAlias,
                serviceAlias,
                articleId
              },
              (draft) => {
                draft.content_meta.reactions.comment_count += 1
              }
            )
          )
        } catch (error) {
          console.error(error)
        }
      },
      keepUnusedDataFor: 0
    }),
    /**
     * 댓글 목록 조회 (더보기)
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     * @param {object} params {
     *  "previous_comment_id": String
     *  "more_size": String
     *  "more_direction": String
     *  "sort": String
     * }
     */
    getCommentList: build.query({
      query: ({ boardAlias, serviceAlias = 'purple', articleId, params }) => {
        return {
          url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}/morecomment`,
          method: 'GET',
          params: params
        }
      },
      providesTags: (result) => {
        return result
          ? [
              ...result.content_list.map(({ content_meta }) => ({
                type: 'Comments',
                id: content_meta.id
              })),
              { type: 'Comments', id: 'LIST' }
            ]
          : [{ type: 'Comments', id: 'LIST' }]
      },
      async onQueryStarted(
        { boardAlias, serviceAlias = 'purple', articleId, ...rest },
        { dispatch, queryFulfilled }
      ) {
        try {
          const {
            data: { total_count = 0 }
          } = await queryFulfilled

          const patchResult = dispatch(
            boardQuery.util.updateQueryData(
              'getArticleDetail',
              {
                boardAlias,
                serviceAlias,
                articleId
              },
              (draft) => {
                draft.content_meta.reactions.comment_count = total_count
              }
            )
          )
        } catch (error) {
          console.error(error)
        }
      },
      serializeQueryArgs: ({
        queryArgs: { articleId, boardAlias, serviceAlias = 'purple', params }
      }) => ({
        articleId,
        boardAlias,
        serviceAlias,
        params: { ...params, previous_comment_id: undefined }
      }),
      merge: (currentCache, newItems, { arg }) => {
        if (!!arg.params.previous_comment_id) {
          currentCache.content_list.push(...newItems.content_list)
        } else {
          currentCache.content_list = newItems.content_list
        }
        currentCache.has_more = newItems.has_more
        currentCache.total_count = newItems.total_count
      },
      keepUnusedDataFor: 0
    }),
    // 댓글 신고
    setCommentReport: build.mutation({
      query: ({ serviceAlias = 'purple', boardAlias, commentId, params }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/comment/${commentId}/report`,
        method: 'POST',
        body: params
      }),
      keepUnusedDataFor: 0
    }),
    /**
     * 리액션 가능 여부
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     */
    getReactionAble: build.query({
      query: ({ boardAlias, serviceAlias = 'purple', articleId, action }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}/reaction/${action}/able`,
        method: 'GET'
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, articleId, action }
      }) => ({ serviceAlias, boardAlias, articleId, action }),
      keepUnusedDataFor: 0
    }),
    /**
     * 리액션 정보 전송
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     */
    postReaction: build.mutation({
      query: ({ boardAlias, serviceAlias = 'purple', articleId, action }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}/reaction/${action}`,
        method: 'POST'
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, articleId, action }
      }) => ({ serviceAlias, boardAlias, articleId, action })
    }),
    /**
     * 리액션 정보 삭제
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     */
    deleteReaction: build.mutation({
      query: ({ boardAlias, serviceAlias = 'purple', articleId, action }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}/reaction/${action}`,
        method: 'DELETE'
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, articleId, action }
      }) => ({ serviceAlias, boardAlias, articleId, action })
    }),
    /**
     * 리액션 정보 조회
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     */
    getReaction: build.query({
      query: ({ boardAlias, serviceAlias = 'purple', articleId }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}/reaction`,
        method: 'GET'
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, articleId }
      }) => ({ serviceAlias, boardAlias, articleId }),
      keepUnusedDataFor: 0
    }),
    /**
     * 게시글 작성
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {object} params {
     *  "article": Object
     *  "category_id": String
     *  "parent_article_id": String
     * }
     */
    postArticle: build.mutation({
      query: ({ serviceAlias = 'purple', boardAlias, params }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article`,
        method: 'POST',
        body: params
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, params }
      }) => ({ serviceAlias, boardAlias, params }),
      invalidatesTags: ['articleDetail', 'articleList']
    }),
    /**
     * 게시글 수정
     * @param {string} boardAlias
     * @param {string} serviceAlias
     * @param {string} articleId
     * @param {object} params {
     *  "article": Object
     *  "category_id": String
     *  "parent_article_id": String
     * }
     */
    putArticle: build.mutation({
      query: ({ serviceAlias = 'purple', boardAlias, articleId, params }) => ({
        url: `/ncommunity/${serviceAlias}/${boardAlias}/article/${articleId}`,
        method: 'PUT',
        body: params
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, params, articleId }
      }) => ({ serviceAlias, boardAlias, params, articleId }),
      invalidatesTags: ['articleDetail', 'articleList']
    }),
    /**
     * 파일 토큰 조회
     * @param {string} boardAlias
     * @param {string} serviceAlias
     */
    getFileToken: build.query({
      query: ({ serviceAlias = 'purple', boardAlias, params }) => ({
        url: `/ncommunity/${serviceAlias}/board/${boardAlias}/upload/token`,
        method: 'GET',
        params
      }),
      serializeQueryArgs: ({
        queryArgs: { serviceAlias = 'purple', boardAlias, params }
      }) => ({ serviceAlias, boardAlias, params }),
      transformResponse: (response) => snakeToCamelObjectKeys(response)
    }),
    /**
     * 나의 게시판 제재 여부 조회 (로그인 체크 겸용)
     * @param {string} serviceAlias
     */
    getMyBan: build.query({
      query: ({ serviceAlias = 'purple' }) => ({
        url: `/ncommunity/${serviceAlias}/user/my/ban`,
        method: 'GET'
      }),
      serializeQueryArgs: ({ queryArgs: { serviceAlias = 'purple' } }) => ({
        serviceAlias
      })
    })
  })
})

export const {
  useGetBoardQuery,
  useGetTodayReportedCountQuery,
  useLazyGetBoardQuery,
  useGetArticleListQuery,
  useLazyGetArticleListQuery,
  useGetTopNoticeListQuery,
  useLazyGetTopNoticeListQuery,
  useGetArticleDetailQuery,
  useGetRecentArticleQuery,
  useDeleteArticleMutation,
  useSetArticleReportMutation,
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
  usePostCommentMutation,
  usePutCommentMutation,
  useDeleteCommentMutation,
  useGetCommentListQuery,
  useLazyGetCommentListQuery,
  useSetCommentReportMutation,
  useGetReactionQuery,
  useGetReactionAbleQuery,
  usePostReactionMutation,
  useDeleteReactionMutation,
  useGetArticleExtraQuery,
  useGetPrevnextArticleQuery,
  useLazyGetFileTokenQuery,
  useGetFileTokenQuery,
  usePostArticleMutation,
  usePutArticleMutation,
  useLazyGetCommentQuery,
  useLazyGetMyBanQuery
} = boardQuery
